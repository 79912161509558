import React from 'react'
import { SuperTag } from '~components'
import Card from './style'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function BlogCard({
  text,
  title,
  date,
  user,
  commentCount,
  badge,
  image,
  ...rest
}) {
  const { t } = useTranslation()

  return (
    <Card>
      <Card.Image>
        <img src={image} alt='Blog' />
      </Card.Image>
      <Card.OvaerlayBlock>
        {/* <Card.Top mb='20px'>
          <Card.Badge backgroundColor='#fcdc00 '>{badge}</Card.Badge>
          <Card.Date to='/innerpage/blog/blogs-details'>{date}</Card.Date>
        </Card.Top> */}
        <Card.Title className='justify-content-center'> {title}</Card.Title>
        <Card.Bottom>
          <Card.User>{user}</Card.User>
          {/* <Card.Comment>See detail</Card.Comment> */}
        </Card.Bottom>
      </Card.OvaerlayBlock>
    </Card>
  )
}
