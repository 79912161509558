import React from 'react'
import { PageWrapper } from '~components/Core'
import HeroSection from '~sections/Home/Hero'
import Blog from '~sections/Home/blog'
import EbtSection from '~sections/Home/ebtSection'
import IotSection from '~sections/Home/iotSection'
import JoinSection from '~sections/Home/joinSection'
import FooterSix from '~sections/Home/footerSix'
import AboutSection from '~sections/Home/About'
import ProcessSection from '~sections/Home/Process'
import IntegrationSection from '~sections/Home/Integration'
import { graphql } from 'gatsby'
// import IotSection from '~sections/Home/iotSection/IotSection'
// import EbtSection from '~sections/Home/ebtSection/EbtSection'
// import JoinSection from '~sections/Home/JoinSection'

const header = {
  headerClasses:
    'site-header site-header--menu-end dark-header site-header--sticky',
  containerFluid: false,
  // buttonBlock: (
  //   <HeaderButton
  //     as={Link}
  //     to='/survey-request'
  //     className='d-none d-sm-flex'
  //     children='Ajukan Survei'
  //   />
  // ),
  darkLogo: false,
}

export default function Marketing() {
  return (
    <PageWrapper headerConfig={header}>
      <HeroSection />
      <AboutSection />
      <Blog />
      <ProcessSection />
      <EbtSection />
      <IotSection />
      <IntegrationSection />
      <JoinSection />
      <FooterSix />
    </PageWrapper>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
