import React from 'react'
import { Link } from '~components'
import ProcessCard from './Components/Card'
import Process from './style'
import { dataProcess } from './Data'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function ProcessSection({ ...rest }) {
  const { t } = useTranslation()
  const ProcessData = dataProcess(t)

  return (
    <Process {...rest} style={{ backgroundColor: '#f3f4f6' }}>
      <Container>
        <Row className='align-items-center justify-content-center justify-content-xl-start'>
          <Col
            xs='12'
            className='col-xl-12 col-lg-12 text-center text-xl-start'
          >
            {/* <Process.Subtitle mbLG='10px' as='h5' fontColor='#5034fc'>
              Our Process
            </Process.Subtitle> */}
            <Process.Title as='h2' mb='40px' mbLG='80px'>
              {t('work', { ns: 'home' })}
            </Process.Title>
          </Col>
          <Col xs='12' className='col-xl-12'>
            <Process.Box as='div' className='widgets'>
              <Row className='justify-content-center justify-content-xl-between'>
                {ProcessData.map(
                  ({ title, text, icon, iconBackground, id, image }, index) => {
                    return (
                      <Col
                        // md='12'
                        xs='12'
                        className='col-lg-3 col-md-6 col-xs-9'
                        // xs='4'
                        // className='col-lg-3 col-md-3 col-xs-3 '
                        key={index}
                      >
                        <ProcessCard
                          icon={icon}
                          title={title}
                          text={text}
                          image={image}
                        />
                      </Col>
                    )
                  },
                )}
              </Row>
            </Process.Box>
          </Col>
        </Row>
      </Container>
    </Process>
  )
}
