import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ReactTypingEffect from 'react-typing-effect'
import { Link } from '~components'
import { Images } from '~data'
import Hero from './style'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function HeroSection() {
  const { t } = useTranslation()

  return (
    <Hero
      style={{
        backgroundImage: `url(${Images.DigiTalagency.heroImg})`,
        height: `100%`,
      }}
    >
      <Container>
        <Row className='align-items-center justify-content-center'>
          <Col className='col-xl-9'>
            <Hero.Content className='text-center'>
              <Hero.Title as='h1' fontColor='#f47621  '>
                <ReactTypingEffect
                  text={['SoBatRp', 'Solar Baterai Rumah Pintar']}
                  className='highlight-text d-inline-block text-warning'
                  speed='150'
                  eraseSpeed='100'
                  typingDelay='400'
                  eraseDelay='800'
                  cursorClassName='typed-cursor'
                />
                {/* {process.env.API_BASE_URL} */}
              </Hero.Title>
              <Hero.Text fontColor='#58595b '>
                {t('subtitle', { ns: 'home' })}
                {/* <br className='d-none d-xs-block' /> Anda yang disesuaikan
                dengan kebutuhan Anda */}
              </Hero.Text>
              <Hero.Button as={Link} to='/#solusi-pintar'>
                {/* ${process.env.API_BASE_URL}/#solusi-pintar*/}
                {/* {'Ini harus diganti menuju linknnya '} */}
                {t('getStarted', { ns: 'home' })}
              </Hero.Button>
            </Hero.Content>
          </Col>
        </Row>
      </Container>
    </Hero>
  )
}
