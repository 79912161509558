import Images from '~data/imageImports'

export const generateBlogData = t => {
  return [
    {
      id: 'bg1',
      image: Images.HomeStartup.blogThumbImg1,
      // badge: 'Gadgets',
      // date: '01 June, 2020',
      title: t('offGrid', { ns: 'home' }),
      user: t('electricity', { ns: 'home' }),
      // commentCount: ' 1',
    },
    {
      id: 'bg2',
      image: Images.HomeStartup.blogThumbImg2,
      // badge: 'Gadgets',
      // date: '01 June, 2020',
      title: t('hybrid', { ns: 'home' }),
      user: t('combiningElectricity', { ns: 'home' }),
      // commentCount: ' 2',
    },
    {
      id: 'bg3',
      image: Images.HomeStartup.blogThumbImg3,
      // badge: 'Gadgets',
      // date: '01 June, 2020',
      title: t('smartHome', { ns: 'home' }),
      user: t('technology', { ns: 'home' }),
      // commentCount: ' 3',
    },
  ]
}

// export default BlogData
