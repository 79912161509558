import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Blog from './style'
import BlogCard from './Components/Card'
import SectionTitle from './Components/SectionTitle'
import BlogData from './Data'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { generateBlogData } from './Data'
import { Link } from '~components'

export default function BlogSection() {
  const { t } = useTranslation()
  const BlogData = generateBlogData(t)

  return (
    <Blog>
      <Container>
        <Row className='row justify-content-center mb-5'>
          <Col className='col-xl-10'>
            <Blog.Box className='text-center'>
              <SectionTitle
                title={t('offering', { ns: 'home' })}
                text={t('solution', { ns: 'home' })}
              />
              <Blog.Button as={Link} to='/services'>
                {t('learnMore', { ns: 'home' })}
              </Blog.Button>
            </Blog.Box>
          </Col>
        </Row>

        <Row className='justify-content-center'>
          {BlogData.map(
            ({ image, badge, date, title, user, commentCount }, idx) => {
              return (
                <Col
                  xs='12'
                  className='col-lg-4 col-md-6 col-xs-9'
                  key={`blogData${idx}`}
                >
                  <BlogCard
                    image={image}
                    title={title}
                    user={user}
                    commentCount={commentCount}
                  />
                </Col>
              )
            },
          )}
        </Row>
      </Container>
    </Blog>
  )
}
