import styled from 'styled-components/macro'
import { Box, Heading, Button, Paragraph } from '~styled'

const Hero = styled(Box)`
  padding-top: 125px;
  padding-bottom: 60px;
  background-size: cover;
  background-position: right top;
  position: center;
  z-index: 0;
  // height: 100vh;

  @media (min-width: 560px) {
    padding-top: 145px;
    padding-bottom: 80px;
    font-size: 18px;
  }

  @media (min-width: 768px) {
    padding-top: 145px;
    padding-bottom: 80px;
    font-size: 18px;
    // height: 90vh;
  }

  @media (min-width: 992px) {
    padding-top: 260px;
    padding-bottom: 195px;
    // height: 100vh;
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    // background: linear-gradient(
    //   360deg,
    //   #f47621 0%,
    //   rgba(244, 118, 33, 1) 50%,
    //   rgba(56, 175, 118, 1) 100%
    // );
    opacity: 0.4;
    position: absolute;
    z-index: -1;
    // background-color: grey
  }
`

Hero.Content = styled(Box)``
Hero.Text = styled(Paragraph)`
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.619;
  text-align: center;
  margin: auto;
  margin-bottom: 55px;
  opacity: 0, 8;

  @media (min-width: 100px) {
    font-size: 14px !important;
    text-align: center;
    width: 340px;
  }

  @media (min-width: 768px) {
    font-size: 17px !important;
    width: 420px;
  }

  @media (min-width: 992px) {
    font-size: 20px;
  }
`
Hero.Title = styled(Heading)`
  font-size: 55px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.2;
  margin-bottom: 25px;

  @media (min-width: 480px) {
    font-size: 50px;
  }

  @media (min-width: 768px) {
    font-size: 60px;
  }

  @media (min-width: 992px) {
    font-size: 70px;
  }
`
Hero.Icon = styled(Box)`
  min-width: 66px;
  max-width: 66px;
  min-height: 66px;
  max-height: 66px;
  border-radius: 50%;
  box-shadow: -12px 12px 50px rgb(253 52 110 / 30%);
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  color: #fd346e;
  justify-content: center;
  margin-bottom: 38px;
  font-size: 21px;
`

Hero.Button = styled(Button)`
  min-width: 180px;
  height: 56px;
  font-size: 15px;
  color: #fff;
  box-shadow: 0 20px 20px rgb(244 118 33 / 10%);
  background-color: #f47621;
  border-color: #f47621;
  transition: 0.4s;
  border-radius: 500px;

  &:hover {
    box-shadow: 0 20px 20px rgb(244 118 33 / 30%);
    background-color: #ff8431;
    color: #fff;
  }
`

export default Hero
