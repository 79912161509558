import styled from 'styled-components/macro'
import { Box, Heading, Button, Paragraph } from '~styled'
import { Images } from '~data'

const Promo = styled(Box)`
  padding-top: 35px;
  padding-bottom: 60px;

  @media (min-width: 768px) {
    padding-top: 55px;
    padding-bottom: 80px;
  }

  @media (min-width: 992px) {
    padding-top: 100px;
    padding-bottom: 130px;
  }
`
Promo.Title = styled(Heading)`
  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.3;
  @media (min-width: 768px) {
    font-size: 38px;
  }

  @media (min-width: 992px) {
    font-size: 48px;
  }
`
Promo.Text = styled(Paragraph)`
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.77777;
`
Promo.ContentBox = styled(Box)`
  // position: relative;
  z-index: 1;
  padding-top: 50px;
  padding-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
  // background-position: center;
  // background-size: stretch;
  border-radius: 25px;

  @media (min-width: 768px) {
    padding-top: 70px;
    padding-bottom: 80px;
    padding-left: 110px;
    padding-right: 100px;
  }

  @media (min-width: 992px) {
    padding-top: 110px;
    padding-bottom: 100px;
  }

  // Linear-gradient(90deg, #F47621 0%, #rgba(244,118,33,1) 0%, rgba(185,86,19,1) 30%, rgba(6,135,74,1) 70%, rgba(56,175,118,1) 100%);

  &::before {
    content: '';
    //
    // background: linear-gradient(
    //   90deg,
    //   #f47621 0%,
    //   rgba(244, 118, 33, 1) 0%,
    //   rgba(185, 86, 19, 1) 30%,
    //   rgba(6, 135, 74, 1) 70%,
    //   rgba(56, 175, 118, 1) 100%
    // );
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 25px;
    z-index: -1;
  }
`
Promo.Button = styled(Button)`
  font-size: 15px;
  background-color: #f47621;
  border-color: #f47621;
  min-width: 180px;
  height: 56px;
  border-radius: 500px;
  color: #fff;
  box-shadow: 0 20px 20px rgb(244 118 33 / 10%);
  transition: 0.4s;
  text-transform: capitalize;
  &:hover {
    box-shadow: 0 20px 20px rgb(244 118 33 / 30%);
    background-color: #ff8431;
    color: #fff;
  }
`
Promo.Box = styled(Box)``
// background-image: url`({Images.PromoSelection.SurveiRequest})`;
export default Promo
