import Images from '~data/imageImports'
const BlogData = [
  {
    id: 'bg1',
    image: Images.BlogsPage.BlogsImg2,
    imageWide: Images.BlogsPage.BlogImgWide1,
    // badge: 'Gadgets',
    // date: '01 June, 2020',
    title: 'We can blend colors multiple',
    // user: 'By George Lee',
    // Like: '21K',
    // commentCount: ' 305',
  },
  {
    id: 'bg2',
    image: Images.BlogsPage.BlogsImg3,
    imageWide: Images.BlogsPage.BlogImgWide2,
    // badge: 'Gadgets',
    // date: '01 June, 2020',
    title: 'We can blend colors multiple',
    // user: 'By George Lee',
    // Like: '21K',
    // commentCount: ' 2',
  },
  {
    id: 'bg3',
    image: Images.BlogsPage.BlogsImg4,
    imageWide: Images.BlogsPage.BlogImgWide3,
    // badge: 'Gadgets',
    // date: '01 June, 2020',
    title: 'We can blend colors multiple',
    // user: 'By George Lee',
    // Like: '21K',
    // commentCount: ' 3',
  },
  {
    id: 'bg4',
    image: Images.BlogsPage.BlogsImg5,
    imageWide: Images.BlogsPage.BlogImgWide4,
    // badge: 'Gadgets',
    // date: '01 June, 2020',
    title: 'We can blend colors multiple',
    // user: 'By George Lee',
    // Like: '21K',
    // commentCount: ' 3',
  },
  {
    id: 'bg5',
    image: Images.BlogsPage.BlogsImg6,
    imageWide: Images.BlogsPage.BlogImgWide5,
    // badge: 'Gadgets',
    // date: '01 June, 2020',
    title: 'We can blend colors multiple',
    // user: 'By George Lee',
    // Like: '21K',
    // commentCount: ' 3',
  },
  {
    id: 'bg6',
    image: Images.BlogsPage.BlogsImg7,
    imageWide: Images.BlogsPage.BlogImgWide6,
    // badge: 'Gadgets',
    // date: '01 June, 2020',
    title: 'We can blend colors multiple',
    // user: 'By George Lee',
    // Like: '21K',
    // commentCount: ' 3',
  },
]
export default BlogData
