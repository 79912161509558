import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SectionTitle from './components/SectionTitle'
import Widget from './components/widget'
import { StaticImage as Img } from 'gatsby-plugin-image'
import About from './style'
import { Link } from '~components'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function AboutSection() {
  const { t } = useTranslation()

  return (
    <About backgroundColor='#fff' id='solusi-pintar'>
      <Container>
        <Row className='justify-content-center'>
          <Col className='col-xl-6 col-lg-8 col-md-9 col-xs-10 text-center'>
            <SectionTitle
              subTitle={t('about', { ns: 'home' })}
              title='SoBatRp'
              titleProps={{ mb: '50px' }}
              subTitleProps={{ mb: '15px' }}
            />
          </Col>
        </Row>
        <Row className='align-items-center justify-content-center'>
          <Col className='col-xxl-3 col-lg-4 col-md-6 col-sm-8 col-xs-9 order-2 order-lg-1'>
            <Widget
              directionXS='row-reverse'
              title={t('profesional', { ns: 'home' })}
              icon='fas fa-user-tie'
              text={t('Combination', { ns: 'home' })}
            />
            <Widget
              // style={{
              //   @maxWidth: '770'
              //     ? (directionXS = 'row-reverse')
              //     : (directionXS = 'row'),
              // }}

              directionXS='row-reverse'
              title={t('rental', { ns: 'home' })}
              icon='fas fa-file-signature'
              text={t('rentalSystem', { ns: 'home' })}
            />
            {/* <Widget
              directionXS='row-reverse'
              title='Innovative solutions'
              icon='fa fa-cog'
              text='The White iphone smartphone mockup counterclockwise '
            /> */}
          </Col>
          <Col
            xs='12'
            className='col-xxl-6 col-lg-4 col-md-8 col-xs-9 order-1 order-lg-2 text-center'
          >
            <About.ImageContent>
              <Img
                src='../../../assets/image/home-app/aboutSobatRp.webp'
                alt='content'
                layout='fullWidth'
                placeholder='blurred'
              />
            </About.ImageContent>
          </Col>
          <Col className='col-xxl-3 col-lg-4 col-md-6 col-sm-8 col-xs-9 order-3'>
            <Widget
              directionXS='row'
              title={t('CombinationWindSolar', { ns: 'home' })}
              icon='fas fa-lightbulb'
              text={t('bigSaving', { ns: 'home' })}
            />
            <Widget
              directionXS='row'
              title={t('freeMaintance', { ns: 'home' })}
              icon='fas fa-tools'
              text={t('maintanceService', { ns: 'home' })}
            />
          </Col>
        </Row>
        <Row className='align-items-center justify-content-center'>
          <Col className='col-xl-2'>
            <About.Section className='text-center'>
              <About.Button as={Link} to='/about'>
                {t('Learn', { ns: 'home' })}
              </About.Button>
            </About.Section>
          </Col>
        </Row>
      </Container>
    </About>
  )
}
