import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from '~components'
import Promo from './style'
import { Images } from '~data'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function JoinSection() {
  const { t } = useTranslation()

  return (
    <Promo>
      <Container>
        <Row className='justify-content-center'>
          <Col className='col-xl-12'>
            <Promo.ContentBox
              className='text-center'
              style={{
                backgroundImage: `url(${Images.PromoSelection.SurveiRequest})`,
                backgroundSize: `100% 100%`,
              }}
            >
              <Promo.Box>
                <Promo.Title as='h2' fontColor='#fff' mb='25px'>
                  {`${t('promo', { ns: 'home' })}`}
                </Promo.Title>
                <Promo.Text fontColor='#fff' mb='30px'>
                  {`${t('subPromo', { ns: 'home' })}`}
                </Promo.Text>
                <Promo.Button as={Link} to='/survey-request'>
                  {`${t('join', { ns: 'home' })}`}
                </Promo.Button>
              </Promo.Box>
            </Promo.ContentBox>
          </Col>
        </Row>
      </Container>
    </Promo>
  )
}
