import styled from 'styled-components/macro'
import { Box, Heading, Button, Paragraph } from '~styled'

const About = styled(Box)`
  // border-bottom: 1px solid #dee2e6;
  padding-top: 35px;
  padding-bottom: 5px;

  @media (min-width: 768px) {
    padding-top: 75px;
    padding-bottom: 25px;
  }

  @media (min-width: 992px) {
    padding-top: 120px;
    padding-bottom: 0px;
  }
`

About.ImageContent = styled(Box)`
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  width: 80%;
  margin: 0 auto;
`
About.Shape = styled(Box)`
  position: absolute;
  top: 50%;
  left: 56%;
  z-index: -1;
  transform: translate(-50%, -50%);
  width: 90%;
`
About.Button = styled(Button)`
  min-width: 180px;
  height: 56px;
  font-size: 15px;
  color: #fff;
  box-shadow: 0 20px 20px rgb(244 118 33 / 10%);
  background-color: #f47621;
  border-color: #f47621;
  transition: 0.4s;
  border-radius: 500px;

  &:hover {
    box-shadow: 0 20px 20px rgb(244 118 33 / 30%);
    background-color: #ff8431;
    color: #fff;
  }
`
About.Section = styled(Box)``

About.Box = styled(Box)``

export default About
