import React from 'react'
import { StaticImage as Img } from 'gatsby-plugin-image'
import Content from './style'
import { SuperTag } from '~components'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Link } from '~components'

export default function EbtSection() {
  const { t } = useTranslation()

  return (
    <Content>
      {/* <Content.SectionShape>
        <Img
          src='../../../assets/image/project-management/l2-content-1-shape.png'
          alt='content'
          layout='fullWidth'
          placeholder='blurred'
        />
      </Content.SectionShape> */}
      <Container>
        <Content.Inner>
          <Row className='align-items-center justify-content-center justify-content-lg-start'>
            <Col className='col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1'>
              <Content.TextBlock>
                <Content.Title as='h2'>
                  <SuperTag value={`${t('offGrid', { ns: 'home' })}`} />
                </Content.Title>
                <Content.Text>
                  <SuperTag value={`${t('electricity', { ns: 'home' })}`} />
                  <br className='d-none d-xs-block' />{' '}
                </Content.Text>
                <Content.Button as={Link} to='/information'>
                  {t('learnMore', { ns: 'home' })}
                </Content.Button>
              </Content.TextBlock>
            </Col>
            <Col
              xs='12'
              className='col-xl-7 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2'
            >
              <Content.Image>
                <Img
                  className='w-100'
                  src='../../../assets/image/project-management/l2-content-img-1.webp'
                  alt='content'
                  // layout='fullWidth'
                  placeholder='blurred'
                />
              </Content.Image>
            </Col>
          </Row>
        </Content.Inner>
      </Container>
    </Content>
  )
}
