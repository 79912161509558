import Images from '~data/imageImports'

export const dataProcess = t => {
  return [
    {
      id: 'sp1',
      icon: '1',
      image: Images.DigiTalagency.heroImg,
      title: t('offGrid', { ns: 'home' }),
      text: t('Complete', { ns: 'home' }),
    },
    {
      id: 'sp2',
      icon: '2',
      image: Images.DigiTalagency.heroImg,
      title: t('SurveyLocation', { ns: 'home' }),
      text: t('SobatRpTeam', { ns: 'home' }),
    },
    {
      id: 'sp3',
      icon: '3',
      image: Images.DigiTalagency.heroImg,
      title: t('Instalation', { ns: 'home' }),
      text: t('SobatRpInstall', { ns: 'home' }),
    },
    {
      id: 'sp4',
      icon: '4',
      image: Images.DigiTalagency.heroImg,
      title: t('Energy', { ns: 'home' }),
      text: t('WePrioritas', { ns: 'home' }),
    },
  ]
}

// import Images from '~data/imageImports'

// export const generateBlogData = t => {
//   return [
//     {
//       id: 'bg1',
//       image: Images.HomeStartup.blogThumbImg1,
//       // badge: 'Gadgets',
//       // date: '01 June, 2020',
//       title: t('offGrid', { ns: 'home' }),
//       user: t('electricity', { ns: 'home' }),
//       // commentCount: ' 1',
//     },
//     {
//       id: 'bg2',
//       image: Images.HomeStartup.blogThumbImg2,
//       // badge: 'Gadgets',
//       // date: '01 June, 2020',
//       title: t('hybrid', { ns: 'home' }),
//       user: t('combiningElectricity', { ns: 'home' }),
//       // commentCount: ' 2',
//     },
//     {
//       id: 'bg3',
//       image: Images.HomeStartup.blogThumbImg3,
//       // badge: 'Gadgets',
//       // date: '01 June, 2020',
//       title: t('smartHome', { ns: 'home' }),
//       user: t('technology', { ns: 'home' }),
//       // commentCount: ' 3',
//     },
//   ]
// }

// // export default BlogData
